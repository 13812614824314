import type { VNode, FunctionalComponent } from "vue";
export { defineComponent, type PropType } from "vue";

export type GaVueComponent = VNode;

export function createParentComponent<Props = Record<string, never>>(
  component: (props: Props & { children?: GaVueComponent | GaVueComponent[] }) => GaVueComponent,
): FunctionalComponent<Props, Record<string, never>, { default?: void }> /*(props: Props, ctx: GaVueContext) => GaVueComponent*/ {
  return (props, ctx) => component({ ...props, children: ctx.slots.default?.() });
}
