import { assert } from "@utils/assertion";
import { registerModule } from "@/Registry";

type GReCaptcha = {
  execute: (siteKey: string, options: { action: string }) => Promise<string>;
  ready: (p: () => void) => void;
};

declare const grecaptcha: GReCaptcha;

export function initBotProtection(element: Element): void {
  const siteKey = "6Lf9SoAlAAAAAEamErYdj0HurO2nRxIk5UhQXjcW";
  const botProtectionScript: HTMLScriptElement = document.createElement("script");
  botProtectionScript.async = true;
  botProtectionScript.src = "https://www.google.com/recaptcha/api.js?render=" + siteKey;

  botProtectionScript.onload = () => {
    assert(element instanceof HTMLFormElement);

    element.onsubmit = (e) => {
      e.preventDefault();
      grecaptcha.ready(function () {
        grecaptcha.execute(siteKey, { action: "submit" }).then((token: string) => {
          const hiddenInput = document.createElement("input");
          hiddenInput.type = "hidden";
          hiddenInput.name = "g-recaptcha-response";
          hiddenInput.value = token;
          element.appendChild(hiddenInput);
          element.submit();
        });
      });
    };
  };
  document.head.appendChild(botProtectionScript);
}

registerModule("[data-ga-bot-protection]", initBotProtection);
