import { createParentComponent } from "@/common/vueUtils";

type Variant = "info" | "success" | "danger" | "warning" | "dark";
type GaAlertProps = {
  variant?: Variant;
  icon?: string;
  closeAlert?: () => void;
};

export const GaAlert = createParentComponent<GaAlertProps>((props) => {
  const variant = props.variant ?? "info";
  const computedClass = (): string[] => {
    const classes = ["alert", `alert-${variant}`];
    if (props.closeAlert !== undefined) {
      classes.push("alert-dismissible", "fade", "show");
    }
    if (props.icon !== undefined) {
      classes.push("d-flex", "align-items-center");
    }
    return classes;
  };

  return (
    <div role="alert" class={computedClass()}>
      {props.icon !== undefined ? (
        <div class={`alert-icon-${variant}`}>
          <i class={props.icon} aria-label={`${variant}:`}></i>
        </div>
      ) : null}
      <div class="alert-body text-start">{props.children}</div>
      {props.closeAlert !== undefined ? <button type="button" class="btn-close" aria-label="Close" onClick={props.closeAlert}></button> : null}
    </div>
  );
});
