import type { GaVueComponent } from "@/common/vueUtils";
import type { WithOptional } from "@utils/formUtils";
import type { Store } from "@/common/storeUtils";
import type { DeepReadonly } from "vue";

type Variant = "text" | "email" | "password" | "search" | "number" | "date";

type Props = {
  type: Variant;
  value: string;
  onUpdateValue: (newValue: string) => void;
  onFocus?: () => void;
  label?: string;
  name: string;
  help?: string;
  disabled: boolean;
  required: boolean;
  placeholder: string;
  errors?: DeepReadonly<string[]>;
};
type PropsWithOptional = WithOptional<Props, "type" | "disabled" | "required" | "placeholder">;

function addDefaults(props: PropsWithOptional): Props {
  return {
    ...props,
    type: props.type ?? "text",
    required: props.required ?? false,
    placeholder: props.placeholder ?? "",
    disabled: props.disabled ?? false,
  };
}

export function GaFormFieldInputText(p: PropsWithOptional): GaVueComponent {
  const props = addDefaults(p);
  const errors = props.errors ?? [];

  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label} {props.required ? <span>*</span> : null}
        </label>
      ) : null}
      <input
        class={{
          "form-control": true,
          "is-invalid": errors.length > 0,
        }}
        onFocus={props.onFocus}
        type={props.type}
        value={props.value}
        onInput={(e) => props.onUpdateValue((e.target as HTMLInputElement).value)}
        name={props.name}
        placeholder={props.placeholder}
        id={props.name}
        required={props.required}
        disabled={props.disabled}
      />
      {errors.map((error) => (
        <div class="invalid-feedback">{error}</div>
      ))}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}

// WIP: please do not use yet
export function GaFormFieldInputTextExperimental(props: {
  store: Store<string>;
  name: string;
  type?: Variant;
  onFocus?: () => void;
  onBlur?: () => void;
  label?: string;
  help?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  errors?: DeepReadonly<string[]>;
}): GaVueComponent {
  const errors = props.errors ?? [];
  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label} {props.required === true ? <span>*</span> : null}
        </label>
      ) : null}
      <input
        class={{
          "form-control": true,
          "is-invalid": errors.length > 0,
        }}
        onFocus={props.onFocus}
        type={props.type}
        value={props.store.get()}
        onInput={(e) => props.store.set((e.target as HTMLInputElement).value)}
        onBlur={props.onBlur}
        name={props.name}
        placeholder={props.placeholder}
        id={props.name}
        required={props.required === true}
        disabled={props.disabled === true}
      />
      {errors.map((error) => (
        <div class="invalid-feedback">{error}</div>
      ))}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}
