import type { GaVueComponent } from "@/common/vueUtils";
import type { MarkdownNode } from "@/components/general/GaMarkdownContent";

type NodeType = Node | MarkdownNode;
type RenderResult = GaVueComponent;
type IntermediateRenderResult = GaVueComponent | "next-renderer";
export type Renderer<N extends NodeType> = (input: N[]) => (GaVueComponent | null)[];
export type DefaultRenderer<N extends NodeType> = (node: N, renderer: Renderer<N>) => RenderResult;
export type IntermediateRenderer<N extends NodeType> = (node: N, renderer: Renderer<N>) => IntermediateRenderResult;

export function createRenderer<N extends NodeType>(defaultRenderer: DefaultRenderer<N>, ...intermediateRenderers: IntermediateRenderer<N>[]): Renderer<N> {
  function applyIntermediateRenderers(node: N, renderer: Renderer<N>): IntermediateRenderResult {
    return intermediateRenderers.reduce<IntermediateRenderResult>((result, nextRenderer) => {
      if (result === "next-renderer") {
        return nextRenderer(node, renderer);
      }
      return result;
    }, "next-renderer");
  }

  const renderer: Renderer<N> = (nodes) => {
    return nodes.map((node) => {
      const result = applyIntermediateRenderers(node, renderer);
      if (result === "next-renderer") {
        return defaultRenderer(node, renderer);
      }
      return result;
    });
  };
  return renderer;
}
