import { registerModule } from "@/Registry";

export function initTooltip(tooltip: Element): void {
  const bsTooltip = new window.bootstrap.Tooltip(tooltip);
  const toggleable = tooltip.getAttribute("data-ga-tooltip-toggleable") === "true";

  if (toggleable) {
    tooltip.addEventListener("ga.tooltip.hide", () => {
      bsTooltip.disable();
    });

    tooltip.addEventListener("ga.tooltip.show", () => {
      bsTooltip.enable();
    });
  }
}

export function toggleTooltips(scope: Element, enable: boolean): void {
  const eventType = enable ? "ga.tooltip.show" : "ga.tooltip.hide";
  scope.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltip) => {
    tooltip.dispatchEvent(new CustomEvent(eventType));
  });
}

registerModule('[data-bs-toggle="tooltip"]', initTooltip);
