import "./_chip.scss";
import { registerModule } from "@/Registry";
import { assert } from "@utils/assertion";

function initGaChip(chip: Element): void {
  const removeBtn = chip.querySelector<HTMLButtonElement>("[data-ga-chip-remove]");

  if (removeBtn != null) {
    removeBtn.addEventListener("click", () => {
      const event = new CustomEvent("close.ga.chip", { bubbles: true });
      chip.dispatchEvent(event);

      if (!event.defaultPrevented) {
        chip.remove();
      }
    });
  }
  const input = chip.querySelector<HTMLInputElement>("input");
  if (input != null) {
    input.addEventListener("change", (e) => {
      assert(e.target instanceof HTMLInputElement);
      if (e.target.checked) {
        input.dispatchEvent(new CustomEvent("activated.ga.chip", { bubbles: true }));
      } else {
        input.dispatchEvent(new CustomEvent("change.ga.chip", { bubbles: true }));
      }
    });
  }
}

registerModule("[data-ga-chip]", initGaChip);
