/*
 * Shorthand plugin that can be declared on links (onClick) or on tags (onReady) to log pageViews to the analytic API (no JS code needed).
 * There are basically two major cases:
 *
 * 1) An element rendered with the data-ga-analytic="<META Base64 String>"
 * 2) Any Ajax request that response contains the "Ga-Meta" Tag.
 */

import { registerModule } from "@/Registry";
import type { PageViewPayload } from "@generated/model/pageViewPayload";
import { getCsrfToken } from "@utils/csrfUtils";

const isIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const handlePageView = async (meta: string | null): Promise<void> => {
  if (meta == null) {
    return;
  }
  const payload: PageViewPayload = {
    meta: meta,
    iframe: isIframe(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  await fetch("/gaapi/act/pv", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": await getCsrfToken(),
    },
    // eslint-disable-next-line no-restricted-syntax
    body: JSON.stringify(payload),
  });
};

export async function initGaAnalytic(element: Element): Promise<void> {
  await handlePageView(element.getAttribute("data-ga-analytic"));
}

registerModule("[data-ga-analytic]", (e) => initGaAnalytic(e));
