import type { GaVueComponent } from "@/common/vueUtils";
import { createTextVNode, h } from "vue";
import { ensureNonNull } from "@utils/assertion";
import { type IntermediateRenderer, createRenderer, type DefaultRenderer } from "@/common/rendererUtils";

export function parseHtmlToDom(html: string): Node[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return Array.from(ensureNonNull(doc.querySelector("body")?.childNodes));
}

const defaultHtmlRenderer: DefaultRenderer<Node> = (node, renderer) => {
  if (!(node instanceof Element)) {
    return createTextVNode(node.textContent ?? undefined);
  }
  const children = renderer(Array.from(node.childNodes));

  if (node instanceof HTMLAnchorElement) {
    return (
      <a href={node.getAttribute("href") ?? undefined} target={node.getAttribute("target") ?? undefined}>
        {...children}
      </a>
    );
  } else if (["B", "I", "U", "STRONG", "EM"].includes(node.tagName)) {
    return h(node.tagName.toLowerCase(), {}, children);
  } else {
    return <>{...children}</>;
  }
};

export function GaHtmlContent(props: { input: string; intermediateRenderers?: IntermediateRenderer<Node>[] }): GaVueComponent {
  const renderer = createRenderer(defaultHtmlRenderer, ...(props.intermediateRenderers ?? []));
  return <>{renderer(parseHtmlToDom(props.input))}</>;
}
