import type { GaVueComponent } from "@/common/vueUtils";
import { assert, assertIsDefined } from "@utils/assertion";
import type { DeepReadonly } from "vue";
import type { Store } from "@/common/storeUtils";
import type { WithOptional } from "@utils/formUtils";

export type SelectOption<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  value: T;
  options: DeepReadonly<SelectOption<T>[]>;
  onInputValue: (value: DeepReadonly<T>) => void;
  label?: string;
  name: string;
  help?: string;
  disabled: boolean;
  required: boolean;
  errors?: DeepReadonly<string[]>;
};

type PropsWithOptional<T> = WithOptional<Props<T>, "disabled" | "required">;

function addDefaults<T>(props: PropsWithOptional<T>): Props<T> {
  return {
    ...props,
    disabled: props.disabled ?? false,
    required: props.required ?? false,
  };
}

export function GaFormFieldInputSelect<T>(p: PropsWithOptional<T>): GaVueComponent {
  const props = addDefaults(p);
  const errors = props.errors ?? [];

  function onInput(event: Event): void {
    assert(event.target instanceof HTMLSelectElement);
    const value = props.options[event.target.selectedIndex];
    assertIsDefined(value);
    props.onInputValue(value.value);
  }

  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label}
        </label>
      ) : null}
      <select
        class={{
          "form-control form-select": true,
          "is-invalid": errors.length > 0,
        }}
        name={props.name}
        onInput={onInput}
        disabled={props.disabled}
        required={props.required}>
        {props.options.map((option) => (
          <option selected={props.value === option.value}>{option.label}</option>
        ))}
      </select>
      {errors.map((error) => (
        <div class="invalid-feedback">{error}</div>
      ))}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}

// WIP: please do not use yet
export function GaFormFieldInputSelectExperimental<T>(props: { store: Store<T>; options: DeepReadonly<SelectOption<T>[]>; onBlur?: () => void; label?: string; name: string; errors?: DeepReadonly<string[]> }): GaVueComponent {
  const errors = props.errors ?? [];

  function onInput(event: Event): void {
    assert(event.target instanceof HTMLSelectElement);
    const value = props.options[event.target.selectedIndex];
    assertIsDefined(value);
    props.store.set(value.value as T); // TODO: casting needed?

    props.onBlur?.(); // TODO: Ugly for now, nicer solution. Exception for select for validation purposes?
  }

  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label}
        </label>
      ) : null}
      <select
        class={{
          "form-control": true,
          "form-select": true,
          "is-invalid": errors.length > 0,
        }}
        name={props.name}
        onInput={onInput}
        onBlur={props.onBlur}>
        {props.options.map((option) => (
          <option selected={props.store.get() === option.value}>{option.label}</option>
        ))}
      </select>
      {errors.map((error) => (
        <div class="invalid-feedback">{error}</div>
      ))}
    </div>
  );
}
