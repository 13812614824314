/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export namespace AreaOfExpertise {
  const _values = [ "ACCOUNTING_AND_FINANCE", "ADMINISTRATION", "BUSINESS_DEVELOPMENT", "COMMUNICATIONS", "CUSTOMER_SERVICE", "HUMAN_RESOURCES", "INFORMATION_TECHNOLOGY", "LEGAL", "MARKETING", "OPERATIONS", "PRODUCTION", "SALES", "SUPPLY_MANAGEMENT", "OTHER", "UNDEFINED" ] as const;
  export type Impl = typeof _values[number];
  export function getValues() {
    return _values;
  }
}
export type AreaOfExpertise = AreaOfExpertise.Impl;

export const parseAreaOfExpertise = (value: AreaOfExpertise): AreaOfExpertise => {
    return value;
}

export const renderAreaOfExpertise = (value: AreaOfExpertise): AreaOfExpertise => {
    return value;
}
