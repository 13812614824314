import { createParentComponent } from "@/common/vueUtils";

type Variant = "primary" | "primary-alt" | "secondary" | "secondary-alt" | "danger" | "danger-alt" | "success" | "link" | "link-slim";
type Size = "sm" | "lg";
type Type = "button" | "submit" | "reset";
type Target = "_self" | "_blank" | "_parent" | "_top";
type ComputedLink = { kind: "other"; href: string } | { kind: "none" };
type GaButtonProps = {
  variant?: Variant;
  size?: Size;
  type?: Type;
  href?: string;
  target?: Target;
  disabled?: boolean;
  active?: boolean;
  title?: string;
  id?: string;
  onClick?: () => void;
};

export const GaButton = createParentComponent<GaButtonProps>((props) => {
  const computedLink: ComputedLink = props.href === undefined ? { kind: "none" } : { kind: "other", href: props.href };

  const computedClass = (): string => {
    const variant = (): string => {
      if (props.variant === "secondary") {
        return "btn-secondary";
      }
      if (props.size === "sm" && props.variant === "primary-alt") {
        return "btn-alt-primary";
      }
      if (props.variant === "primary-alt") {
        return "btn-outline-primary";
      }
      if (props.size === "sm" && props.variant === "secondary-alt") {
        return "btn-alt-secondary";
      }
      if (props.variant === "secondary-alt") {
        return "btn-outline-secondary";
      }
      if (props.variant === "danger") {
        return "btn-danger";
      }
      if (props.size === "sm" && props.variant === "danger-alt") {
        return "btn-alt-danger";
      }
      if (props.variant === "danger-alt") {
        return "btn-outline-danger";
      }
      if (props.variant === "success") {
        return "btn-success";
      }
      if (props.variant === "link") {
        return "btn-link";
      }
      if (props.variant === "link-slim") {
        return "btn-link p-0 text-start";
      }
      return "btn-primary";
    };

    const size = (): string => {
      if (props.size === "sm" || props.size === "lg") {
        return `btn-${props.size}`;
      }
      return "";
    };

    const disabled = (): string => (props.disabled === true ? "disabled" : "");
    const active = (): string => (props.active === true ? "active" : "");

    return ["btn", variant(), size(), disabled(), active()].join(" ");
  };

  switch (computedLink.kind) {
    case "none":
      return (
        <button class={computedClass()} disabled={props.disabled} type={props.type} tabindex="0" title={props.title} id={props.id} onClick={props.onClick}>
          {props.children}
        </button>
      );
    case "other":
      return (
        <a href={computedLink.href} class={computedClass()} aria-disabled={props.disabled} tabindex={props.disabled === true ? -1 : undefined} title={props.title} id={props.id} target={props.target} onClick={props.onClick}>
          {props.children}
        </a>
      );
  }
});
